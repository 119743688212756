import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TreeNode } from 'primeng/primeng';
import { _HttpClient, NotificationService } from '@service/common';
import { AppService } from '@service/common/app.service';
import { urlSerialize } from '@shared';
@Component({
  selector: 'ngx-base-massif-choose',
  templateUrl: './choose.html',
  styleUrls: ['./choose.css'],
})
export class BaseMassifChoose implements OnInit {
  modalData: Object = {}; //弹框数据
  selectedNode: TreeNode = null; //选中的节点
  treeData: TreeNode[] = []; //树数据
  flagInsert: boolean = false; //新增判断
  flagUpdate: boolean = false; //修改判断
  greenLandData: boolean = false; //育苗地块显示

  constructor(
    private http: _HttpClient,
    private appSrv: AppService,
    private notifySrv: NotificationService,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit() {
    this.loadData();
    this.flagInsert = this.modalData['flagInsert'];
    this.flagUpdate = this.modalData['flagUpdate'];
    this.greenLandData = this.modalData['greenLandData'];
  }

  /**
   * 获取数据
   */
  loadData() {
    this.http.post(urlSerialize('BaseLandPc/selectBaseLandTree', 'Base_four'), {}).subscribe(result => {
      if (result != null && result.length > 0) {
        this.treeData = this.convTreeNode(result);
      }
    });
  }

  /**
   * 返回的结果转换为树节点需要的数据格式
   */
  convTreeNode(result) {
    let tempData: TreeNode[] = [];
    if (Array.isArray(result)) {
      for (let i = 0; i < result.length; i++) {
        const treeNode: TreeNode = {};
        treeNode.label = result[i]['landName'];
        // treeNode.icon = result[i]['iconCls'];
        treeNode.expanded = result[i]['expandable'];
        treeNode.leaf = result[i]['leaf'];
        //newNode.checked = model_back[i].checked;
        // treeNode.landNickName = result[i]['landNickName'];
        treeNode.data = {
          id: result[i]['id'],
          recNo: result[i]['recNo'],
        };

        if (this.appSrv.isNotEmpty(this.modalData['landNo']) && this.modalData['landNo'] == result[i]['recNo']) {
          this.selectedNode = treeNode;
        }
        tempData = [...tempData, treeNode];
        if (!treeNode.leaf) {
          const temp = result[i].children;
          if (Array.isArray(temp)) {
            tempData[i].children = this.convTreeNode(temp);
          } else {
            tempData[i].children = [];
          }
        }
      }
    }

    return tempData;
  }

  /**
   * 取消 关闭窗口
   */
  closeModal() {
    this.activeModal.close();
  }

  /**
   * 选择 关闭窗口
   */
  dismissModal() {
    if (this.selectedNode != null) {
      if (this.selectedNode.parent) {
        //解决数据加载慢、无数据显示时点击"选择"按钮报错
        this.activeModal.dismiss(this.selectedNode);
      } else {
        const msg = '请选择子地块！';
        this.notifySrv.tooltip({ message: msg });
      }
    } else {
      const msg = '请选择地块再提交！';
      this.notifySrv.tooltip({ message: msg });
    }
  }
}
