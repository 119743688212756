/**
 * 地块选择Module
 */
import { NgModule } from '@angular/core';
import { BaseMassifChoose } from './choose';
import { ThemeModule } from '@theme/theme.module';
import { TreeModule } from 'primeng/primeng';

@NgModule({
  imports: [
    ThemeModule,
    TreeModule,
  ],
  declarations: [
    BaseMassifChoose,
  ],
  exports: [BaseMassifChoose],
  entryComponents: [BaseMassifChoose],
})
export class BaseMassifChooseModule { }
